import { DBConfig } from 'ngx-indexed-db';

export const BiskitsDbConfig: DBConfig = {
    name: 'BiskitsDb',
    version: 5,
    objectStoresMeta: [
        {
            store: 'general',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [],
        },
        {
            store: 'auth',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [],
        },
        {
            store: 'userPref',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [],
        },
        {
            store: 'translations',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [],
        },
    ],
};

export enum EB360LocalDbs {
    GENERAL_DATA = 'general',
    AUTH = 'auth',
    USER_PREF = 'userPref',
    TRANSLATIONS = 'translations',
}
