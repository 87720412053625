import { IEmployee } from './shared.interfaces';
import { WithEmployeeObject, WithEmployeeString } from './types';

export abstract class B360Utils {
    // Overload for a single object
    static populateWithEmployeeData<T extends object>(
        data: WithEmployeeString<T>,
        emps: IEmployee[],
    ): WithEmployeeObject<T>;

    // Overload for an array of objects
    static populateWithEmployeeData<T extends object>(
        data: Array<WithEmployeeString<T>>,
        emps: IEmployee[],
    ): WithEmployeeObject<T>[];

    static populateWithEmployeeData<T extends object>(
        data: any,
        emps: IEmployee[],
    ) {
        if (Array.isArray(data)) {
            return data.map((item) =>
                this.replaceEmployee(item, emps),
            ) as WithEmployeeObject<T>[];
        } else {
            return this.replaceEmployee(data, emps) as WithEmployeeObject<T>;
        }
    }

    static B360customFilterPredicate() {
        return (data: any, filter: string): boolean => {
            const accumulator = (currentTerm, key) => {
                return this._flattenData(currentTerm, data[key]);
            };
            const dataStr = Object.keys(data)
                .reduce(accumulator, '')
                .toLowerCase();

            return dataStr.indexOf(filter) !== -1;
        };
    }

    private static _flattenData(result: string, value: any): string {
        if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
                // Process array elements
                value.forEach((item) => {
                    result = this._flattenData(result, item);
                });
            } else {
                // Process object properties
                Object.values(value).forEach((val) => {
                    result = this._flattenData(result, val);
                });
            }
        } else {
            // Concatenate value if it's not an object or array
            result += `${value} `;
        }
        return result;
    }

    // Helper method to replace the employee string with the employee object
    private static replaceEmployee<T extends object>(
        item: WithEmployeeString<T>,
        emps: IEmployee[],
    ): WithEmployeeObject<T> {
        const emp = emps.find((e) => e._id === item.employee);
        return {
            ...item,
            employee: emp ?? item.employee,
        } as WithEmployeeObject<T>;
    }
    // Utility Function to convert object to string including nested objects
    static objectToString(obj: any): string {
        let str = '';

        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                str += B360Utils.objectToString(obj[key]);
            } else {
                str += obj[key];
            }
        }

        return str.toUpperCase();
    }

    static tryToCall(phoneNumber: string) {
        if (!phoneNumber) {
            return;
        }

        const phoneNumberFormatted = phoneNumber.replace(/\D/g, '');
        if (phoneNumberFormatted.length < 10) {
            return;
        }
        const phoneNumberLink = `tel:${phoneNumberFormatted}`;
        window.open(phoneNumberLink, '_self');
    }

    static tryToSendEmail(email: string) {
        if (!email) {
            return;
        }
        const emailLink = `mailto:${email}`;

        window.open(emailLink, '_self');
    }

    static returnReadableBrowserData() {
        const userAgent = navigator.userAgent;
        const rdBrowserObj = {
            browserType: '',
            browserVersion: '',
        };

        // parse user agent data
        if (userAgent.indexOf('Firefox') > -1) {
            rdBrowserObj.browserType = 'Firefox';
            rdBrowserObj.browserVersion = userAgent.split('Firefox/')[1];
        } else if (
            userAgent.indexOf('Opera') > -1 ||
            userAgent.indexOf('OPR') > -1
        ) {
            rdBrowserObj.browserType = 'Opera';
            rdBrowserObj.browserVersion =
                userAgent.indexOf('OPR') > -1
                    ? userAgent.split('OPR/')[1]
                    : userAgent.split('Opera/')[1];
        } else if (userAgent.indexOf('Trident') > -1) {
            rdBrowserObj.browserType = 'IE';
            rdBrowserObj.browserVersion = userAgent.split('rv:')[1];
        } else if (userAgent.indexOf('Edge') > -1) {
            rdBrowserObj.browserType = 'Edge';
            rdBrowserObj.browserVersion = userAgent.split('Edge/')[1];
        } else if (userAgent.indexOf('Chrome') > -1) {
            rdBrowserObj.browserType = 'Chrome';
            rdBrowserObj.browserVersion = userAgent.split('Chrome/')[1];
        } else if (userAgent.indexOf('Safari') > -1) {
            rdBrowserObj.browserType = 'Safari';
            rdBrowserObj.browserVersion = userAgent.split('Safari/')[1];
        } else {
            rdBrowserObj.browserType = 'Other';
            rdBrowserObj.browserVersion = 'N/A';
        }

        return rdBrowserObj;
    }

    static returnReadableOSAndDeviceData() {
        const userAgent = navigator.userAgent;
        const rdDeviceObj = {
            OS: '',
            deviceType: '',
        };

        // parse user agent data
        if (userAgent.indexOf('Win') > -1) {
            rdDeviceObj.OS = 'Windows';
        } else if (userAgent.indexOf('Mac') > -1) {
            rdDeviceObj.OS = 'Mac OS';
        } else if (userAgent.indexOf('Linux') > -1) {
            rdDeviceObj.OS = 'Linux';
        } else {
            rdDeviceObj.OS = 'Other';
        }

        // device type if it's mobile then OS is MAC OS then return IOS
        // if mobile and not MAC OS then return Android else return Desktop
        if (userAgent.indexOf('Mobile') > -1) {
            if (userAgent.indexOf('Mac') > -1) {
                rdDeviceObj.deviceType = 'IOS';
            } else {
                rdDeviceObj.deviceType = 'Android';
            }
        } else {
            rdDeviceObj.deviceType = 'Desktop';
        }

        return rdDeviceObj;
    }
}

// decorator function
export function B360AutoUnsub() {
    return function (constructor) {
        const orig = constructor.prototype.ngOnDestroy;

        constructor.prototype.ngOnDestroy = function () {
            for (const prop in this) {
                const property = this[prop];
                if (property && typeof property.unsubscribe === 'function') {
                    property.unsubscribe();
                }
            }
            if (orig && typeof orig === 'function') {
                orig.apply(this);
            }
        };
    };
}
