import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { IB360ConfirmDialogData } from '@src/app/shared/helpers/shared.interfaces';

@Component({
    selector: 'b360-confirm-dialog',
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        TranslateModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        FormsModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,

    template: `
        @if(data.titleIcon){
        <mat-icon class="b360DialogOverlappingIcon ">
            {{ data.titleIcon }}
        </mat-icon>
        }

        <h1>{{ data.title | translate }}</h1>

        <p class="textCenter">{{ data.message | translate }}</p>

        @if(data.textList){
        <mat-list>
            @for(txt of data.textList; track $index ){
            <mat-list-item>{{ txt | translate }}</mat-list-item>
            }
        </mat-list>

        } @if(data.needTextApprovel){
        <mat-form-field>
            <input
                [(ngModel)]="approvalText"
                matInput
                placeholder="{{ 'actions.writeYesToConfirm' | translate }}" />
        </mat-form-field>
        }

        <div class="btns">
            @for (btn of data.buttons; track btn) {
            <!-- btn.data means it's confirm btn so can be disabled -->
            <button
                [color]="btn.color"
                [disabled]="
                    data.needTextApprovel &&
                    btn.data &&
                    !['yes', 'כן'].includes(approvalText.toLowerCase())
                "
                mat-flat-button
                class="m-4"
                [mat-dialog-close]="btn.data">
                {{ btn.text | translate }}
            </button>
            }
        </div>
    `,
    styles: [
        `
            :host {
                .textCenter {
                    text-align: center;
                    text-wrap: balance;
                }
            }
        `,
    ],
    host: { class: 'b360DialogWrapper' },
})
export class B360ConfirmDialog implements OnInit {
    public data: IB360ConfirmDialogData = inject(MAT_DIALOG_DATA);
    public dialogRef = inject(MatDialogRef<B360ConfirmDialog>);

    approvalText: string = '';

    ngOnInit(): void {}
}
