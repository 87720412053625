import { Directionality } from '@angular/cdk/bidi';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InitService } from './app/shared/services/app-init.service';

export function initApp(initService: InitService) {
    return () => {
        initService.initData();
    };
}

export function directionFactory(dirService: Directionality) {
    return dirService.value;
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
