import { AssetsMap } from './shared/maps/assets.map';

export const iconsMetaData = [
    {
        iconName: 'b360ComingSoon',
        src: './assets/images/soon.svg',
    },
    {
        iconName: 'b360ToolbarLogo',
        src: './assets/biskitsIcons/b360Logo.svg',
    },
    {
        iconName: 'b360logo',
        src: './assets/icons/logoround.svg',
    },
    {
        iconName: 'apple',
        src: './assets/icons/apple.svg',
    },
    {
        iconName: 'birthday',
        src: './assets/icons/birthday.svg',
    },
    {
        iconName: 'publicFolder',
        src: './assets/icons/publicFolder.svg',
    },
    {
        iconName: 'privateFolder',
        src: './assets/icons/privateFolder.svg',
    },
    {
        iconName: 'privateFile',
        src: './assets/icons/privateFile.svg',
    },
    {
        iconName: 'publicPdf',
        src: './assets/icons/publicPdf.svg',
    },
    {
        iconName: 'accessibility',
        src: './assets/icons/accessibility.svg',
    },
    {
        iconName: 'xls',
        src: './assets/icons/xls.svg',
    },
    {
        iconName: 'privateExcel',
        src: './assets/icons/privateExcel.svg',
    },
    {
        iconName: 'startArrow',
        src: './assets/icons/startArrow.svg',
    },
    {
        iconName: 'privatePdf',
        src: './assets/icons/privatePdf.svg',
    },
    {
        iconName: 'privateWord',
        src: './assets/icons/privateWord.svg',
    },
    {
        iconName: 'privatePpt',
        src: './assets/icons/privatePpt.svg',
    },
    {
        iconName: 'publicImg',
        src: './assets/icons/publicImg.svg',
    },
    {
        iconName: 'privateImg',
        src: './assets/icons/privateImg.svg',
    },
    {
        iconName: 'email',
        src: './assets/icons/email.svg',
    },
    {
        iconName: 'cellphone',
        src: './assets/icons/cellphone.svg',
    },
    {
        iconName: 'excel',
        src: './assets/icons/excel.svg',
    },
    {
        iconName: 'add_emoji',
        src: './assets/icons/add_emoji.svg',
    },
    {
        iconName: 'news',
        src: './assets/icons/news.svg',
    },
    {
        iconName: 'seniority',
        src: './assets/icons/seniority.svg',
    },
    {
        iconName: 'pepole',
        src: './assets/icons/pepole.svg',
    },

    {
        iconName: 'gifts',
        src: './assets/icons/gifts.svg',
    },
    {
        iconName: 'navbar',
        src: './assets/icons/borgerOff.svg',
    },
    {
        iconName: 'lang',
        src: './assets/icons/Lang.svg',
    },
    {
        iconName: 'ok',
        src: './assets/icons/ok.svg',
    },
    {
        iconName: 'user',
        src: './assets/icons/newUser.svg',
    },
    {
        iconName: 'checkbox',
        src: './assets/icons/checkbox.svg',
    },
    {
        iconName: 'select',
        src: './assets/icons/select.svg',
    },
    {
        iconName: 'radio',
        src: './assets/icons/radio.svg',
    },
    {
        iconName: 'append',
        src: './assets/icons/append.svg',
    },
    {
        iconName: 'signature',
        src: './assets/icons/signature.svg',
    },
    {
        iconName: 'divide',
        src: './assets/icons/divide.svg',
    },
    {
        iconName: 'homeOff',
        src: './assets/icons/homeOff.svg',
    },
    {
        iconName: 'payslipOff',
        src: './assets/icons/salaryOff.svg',
    },
    {
        iconName: 'attendanceOff',
        src: './assets/icons/calenderOff.svg',
    },
    {
        iconName: 'chatOff',
        src: './assets/icons/chatOff.svg',
    },
    {
        iconName: 'clockOff',
        src: './assets/icons/attendanceOff.svg',
    },
    {
        iconName: 'homeOn',
        src: './assets/icons/homeOn.svg',
    },
    {
        iconName: 'payslipOn',
        src: './assets/icons/salaryOn.svg',
    },
    {
        iconName: 'attendanceOn',
        src: './assets/icons/calenderOn.svg',
    },
    {
        iconName: 'chatOn',
        src: './assets/icons/chatOn.svg',
    },
    {
        iconName: 'clockOn',
        src: './assets/icons/attendanceOn.svg',
    },
    {
        iconName: 'navbarOn',
        src: './assets/icons/borgerOn.svg',
    },
    {
        iconName: 'navbarOff',
        src: './assets/icons/borgerOff.svg',
    },
    {
        iconName: 'notifsOn',
        src: './assets/icons/notification.svg',
    },
    {
        iconName: 'notifsOff',
        src: './assets/icons/notification.svg',
    },
    {
        iconName: 'docsOn',
        src: './assets/icons/docsOn.svg',
    },
    {
        iconName: 'dg',
        src: './assets/icons/docsOn.svg',
    },
    {
        iconName: 'docsOff',
        src: './assets/icons/docsOff.svg',
    },
    {
        iconName: 'f101',
        src: './assets/icons/101On.svg',
    },
    {
        iconName: 'form101',
        src: './assets/icons/101On.svg',
    },
    {
        iconName: 'calendar',
        src: AssetsMap.icons.attendance,
    },

    {
        iconName: 'contacts',
        src: AssetsMap.icons.contacts,
    },
    {
        iconName: 'docs',
        src: AssetsMap.icons.NEW_DOCUMENT,
    },
    {
        iconName: 'pension',
        src: './assets/icons/pensionOn.svg',
    },
    {
        iconName: 'savings',
        src: './assets/icons/save.svg',
    },
    {
        iconName: 'benefits',
        src: AssetsMap.icons.benefits,
    },
    {
        iconName: 'payslip',
        src: AssetsMap.icons.NEW_PAYSLIP,
    },
    {
        iconName: 'fixBankDetails',
        src: './assets/icons/fixBankDetails.svg',
    },
    {
        iconName: 'chat',
        src: AssetsMap.icons.NEW_CHAT_MSG,
    },
    {
        iconName: 'people',
        src: AssetsMap.icons.people,
    },
    {
        iconName: 'profile',
        src: AssetsMap.icons.settingsProfile,
    },
    {
        iconName: 'support',
        src: AssetsMap.icons.support,
    },
    {
        iconName: 'logOut',
        src: AssetsMap.icons.logOut,
    },
    {
        iconName: 'phonebook',
        src: AssetsMap.icons.phonebook,
    },
    {
        iconName: 'social',
        src: AssetsMap.icons.social,
    },
    {
        iconName: 'form101',
        src: AssetsMap.icons.f101,
    },
    {
        iconName: 'documents',
        src: AssetsMap.icons.NEW_DOCUMENT,
    },
    {
        iconName: 'removeChild',
        src: './assets/icons/removeChild.svg',
    },
    {
        iconName: 'clockNF',
        src: './assets/icons/nofill/clockNoFill.svg',
    },
    {
        iconName: 'benefitNF',
        src: './assets/icons/nofill/benefitNoFill.svg',
    },
    {
        iconName: 'payslipNF',
        src: './assets/icons/nofill/payslipNoFill.svg',
    },
    {
        iconName: 'docsNF',
        src: './assets/icons/nofill/docsNoFill.svg',
    },
    {
        iconName: 'contactsNF',
        src: './assets/icons/nofill/contactNoFill.svg',
    },
    {
        iconName: 'calanderNF',
        src: './assets/icons/nofill/calanderNoFill.svg',
    },
    {
        iconName: 'pensionNF',
        src: './assets/icons/nofill/pensionNoFill.svg',
    },
    {
        iconName: 'savingsNF',
        src: './assets/icons/nofill/saveNoFill.svg',
    },
    {
        iconName: 'chatNF',
        src: './assets/icons/nofill/chatNoFill.svg',
    },
    {
        iconName: 'f101NF',
        src: './assets/icons/nofill/f101NoFill.svg',
    },
    {
        iconName: 'supportNF',
        src: './assets/icons/nofill/supportNoFill.svg',
    },
    {
        iconName: 'updateApp',
        src: './assets/icons/updateApp.svg',
    },
    {
        iconName: 'warning',
        src: './assets/icons/warning.svg',
    },
    {
        iconName: 'vmark',
        src: './assets/icons/vMark.svg',
    },
    {
        iconName: 'cancel',
        src: './assets/icons/no.svg',
    },

    {
        iconName: 'timewatchNF',
        src: './assets/b360Icons/timewatch.svg',
    },
    {
        iconName: 'attendanceNF',
        src: './assets/b360Icons/attendance.svg',
    },
    {
        iconName: 'payslipNF',
        src: './assets/b360Icons/payslip.svg',
    },
    {
        iconName: 'benefitNF',
        src: './assets/b360Icons/benefit.svg',
    },
    {
        iconName: 'form101NF',
        src: './assets/b360Icons/form101.svg',
    },
    {
        iconName: 'documentNF',
        src: './assets/b360Icons/document.svg',
    },
    {
        iconName: 'pensionNF',
        src: './assets/b360Icons/pension.svg',
    },
    {
        iconName: 'savingsNF',
        src: './assets/b360Icons/savings.svg',
    },
    {
        iconName: 'socialNF',
        src: './assets/b360Icons/social.svg',
    },
    {
        iconName: 'phonebookNF',
        src: './assets/b360Icons/phonebook.svg',
    },
    {
        iconName: 'chatNF',
        src: './assets/b360Icons/chat.svg',
    },
    {
        iconName: 'supportNF',
        src: './assets/b360Icons/support.svg',
    },
    {
        iconName: 'socialWC',
        src: './assets/b360Icons/socialOn.svg',
    },
    {
        iconName: 'switch',
        src: './assets/b360Icons/switch.svg',
    },
    {
        iconName: 'attachedDocs',
        src: './assets/b360Icons/attachedDocs.svg',
    },
    {
        iconName: 'approvedDocs',
        src: './assets/b360Icons/approvedDocs.svg',
    },

    {
        iconName: 'broto',
        src: './assets/icons/broto.svg',
    },
    {
        iconName: 'taxes',
        src: './assets/icons/taxes.svg',
    },
    {
        iconName: 'total',
        src: './assets/icons/total.svg',
    },
    {
        iconName: 'savings',
        src: './assets/icons/savings.svg',
    },
    {
        iconName: 'workdays',
        src: './assets/icons/workdays.svg',
    },
    {
        iconName: 'vacation',
        src: './assets/icons/vacation.svg',
    },
    {
        iconName: 'defaultPension',
        src: './assets/icons/defaultPension.svg',
    },
    {
        iconName: 'bizDeal',
        src: './assets/icons/bizDeal.svg',
    },
    {
        iconName: 'noDataFound',
        src: './assets/icons/noDataFound.svg',
    },
    {
        iconName: 'employerPensionBenefit',
        src: './assets/icons/employerPensionBenefit.svg',
    },
    {
        iconName: 'defaultPensionStar',
        src: './assets/icons/defaultPensionStar.svg',
    },
    {
        iconName: 'employeeOnboarding',
        src: './assets/icons/employee-onboarding.svg',
    },
];
