export const RobotsMetaTags = [
    {
        name: 'keywords',
        content: 'B360, b360, B360.HR, b360hr, B360HR',
    },
    { name: 'robots', content: 'index, follow' },
    { name: 'author', content: 'Biskits Tech.' },
    {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1',
    },
    { name: 'date', content: '2019-10-31', scheme: 'YYYY-MM-DD' },
    {
        name: 'description',
        content:
            'מערכת ניהול משאבי אנוש מתקדמת - דירוג עובדים, ניהול הטבות, 101 דיגטלי, תהליכי קליטה וסיום העסקה (מסמכים דיגיטליים), עץ ארגוני חכם, תיק עובד , הצטרפות לכל קרן פנסיה והשתלמות בקליק, כל המידע הביטוחי של העובד באזור אישי, מערכת פניות ארגונית, ניהול משמרות, דיווח נוכחות, התאמה אישית של מודולים לארגון שלכם',
    },
    { charset: 'UTF-8' },
];
