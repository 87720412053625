import { Directionality } from '@angular/cdk/bidi';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withInterceptorsFromDi,
    withJsonpSupport
} from '@angular/common/http';
import {
    APP_INITIALIZER,
    ApplicationConfig,
    LOCALE_ID,
    importProvidersFrom,
} from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';

import { getStorage, provideStorage } from '@angular/fire/storage';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/datepicker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '@src/environments/environment';

import { provideCloudflareLoader } from '@angular/common';
import {
    HAMMER_GESTURE_CONFIG,
    HammerGestureConfig,
} from '@angular/platform-browser';

import {
    HttpLoaderFactory,
    directionFactory,
    initApp,
} from '@src/main-factories';
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    PieController,
} from 'chart.js';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { provideCharts } from 'ng2-charts';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { B360_APP_ROUTES } from './app.routes';
import { B360_FULLDATE_FORMAT } from './dateFormats';
import { BiskitsDbConfig } from './localDB';
import { B360GlobalResponseHandlerInterceptor } from './shared/interceptors/http-response.interceptor';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { InitService } from './shared/services/app-init.service';

// ServiceWorkerModule.register('ngsw-worker.js', {
//     enabled: environment.production,
//     // ServiceWorker registration strategy
//     registrationStrategy: 'registerImmediately',
// }),

// BELONGS TO ROUTER PROVIDER BUT CURRENTLY HAVE A BUG IN DOCUMENTS/FORMEDITOR
// IT CRAHES THE APP WHEN USING IT
// withViewTransitions({ skipInitialTransition: true })
export const B360Config: ApplicationConfig = {
    providers: [
        provideRouter(B360_APP_ROUTES),
        

        // Importing necessary modules and services
        importProvidersFrom(            
            
            
            NgxIndexedDBModule.forRoot(BiskitsDbConfig),
            TranslateModule.forRoot({
                defaultLanguage: 'he',
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                },
            }),
        ),

        // Application initializer to run custom initialization logic
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            multi: true,
            deps: [InitService],
        },

        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaV3 },

        // HammerJS gesture configuration
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig,
            deps: [],
        },

        // Material Design components scroll strategy providers
        MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
        MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,

        // HTTP interceptor for token management
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },

        // HTTP interceptor for errors management
        {
            provide: HTTP_INTERCEPTORS,
            useClass: B360GlobalResponseHandlerInterceptor,
            multi: true,
        },

        // Configuration for Material Tabs animation duration
        { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },

        // Default options for Material Snackbar
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 3500,
                panelClass: 'b360snackbar',
                direction: directionFactory,
            },
            deps: [Directionality],
        },

        // Default options for Material Form Fields
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' },
        },

        // Locale configuration for dates
        { provide: LOCALE_ID, useValue: 'he-IL' },
        { provide: MAT_DATE_LOCALE, useValue: 'he-IL' },

        // Moment Date Adapter options for working with UTC dates
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true },
        },

        // Full date format configuration
        {
            provide: MAT_DATE_FORMATS,
            useValue: B360_FULLDATE_FORMAT,
        },

        // Date Adapter configuration using MomentDateAdapter
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        // Animations provider
        provideAnimationsAsync(),

        provideCloudflareLoader('https://b360.hr'),

        // HTTP client with interceptors
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),

        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideStorage(() => getStorage()),
        provideMessaging(() => getMessaging()),

        provideCharts({
            registerables: [
                PieController,
                ArcElement,
                CategoryScale,
                LinearScale,
                BarController,
                BarElement,
            ],
        }),
    ],
};
