import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { B360SnackHelper } from '../helpers/B360SnackHelper';
import { B360_SNACK_MSG } from '../helpers/shared.const';
import { ApiResponse } from '../helpers/shared.interfaces';

@Injectable()
export class B360GlobalResponseHandlerInterceptor implements HttpInterceptor {
    private snack = inject(MatSnackBar);

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            // Handle successful responses
            tap((evt: HttpResponse<ApiResponse>) => {
                if (evt?.body?.ok && evt?.body?.msg) {
                    return B360SnackHelper.showSnack(
                        evt.body.msg,
                        'SUCCESS',
                        this.snack,
                    );
                }

                if (evt?.body?.ok && evt?.body?.showSnack && !evt?.body?.msg) {
                    return B360SnackHelper.showSnack(
                        B360_SNACK_MSG.success.general,
                        'SUCCESS',
                        this.snack,
                    );
                }

                if (evt?.body?.error) {
                    throwError(() => new Error(evt.body.data));
                    return B360SnackHelper.showSnack(
                        evt.body.data,
                        'ERROR',
                        this.snack,
                    );
                }
            }),
            // Handle errors
            catchError((error: HttpErrorResponse) => {
                B360SnackHelper.showSnack(
                    error.error?.msg || B360_SNACK_MSG.error.apiError,
                    'ERROR',
                    this.snack,
                );
                return throwError(() => new Error(error.message));
            }),
        );
    }
}
